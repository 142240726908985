import { useEffect } from 'react';

const Linkedin = () => {
    useEffect(() => {
        window.location.href = 'https://linkedin.com/in/abhinavranish';
    }, []);

    return null;
};

export default Linkedin;